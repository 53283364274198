import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, Card, Menu, message, Modal, Row, Tabs } from "antd";
import duration from "dayjs/plugin/duration";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// General Reports Components
import Campaigns from "../components/Campaigns";
import AgentsHistorical from "../components/agents/AgentsHistorical";
import AgentsRealTime from "../components/agents/AgentsRealTime";
import CampaignsHistorical from "../components/campaigns/CampaignsHistorical";
import CampaignsRealTime from "../components/campaigns/CampaignsRealTime";
// Key Accounts Reports Components
import KeyHistoricalNew from "../components/key-accounts-campaigns/KeyHistoricalNew";
import KeyRealTime from "./../components/key-accounts-campaigns/KeyRealTime";
import KeyAgentsHistorical from "../components/key-accounts-agents/KeyAgentsHistorical";
import KeyAgentsRealTime from "../components/key-accounts-agents/KeyAgentsRealTime";
// Manuals Reports Components
import ManualsCampaignsHistorical from "../components/manuals/ManualsCampaignsHistorical";
import ManualsRealTime from "../components/manuals/ManualsRealTime";
import ManualsAgentsHistorical from "../components/manuals/agents/ManualsAgentsHisorical";
import ManualsAgentsRealTime from "../components/manuals/agents/ManualsAgentsRealTime";
// WhatsApp Resports Components
import WhatsAppHistorical from "../components/whatsapp/WhatsAppHistorical";
import WhatsAppRealTime from "../components/whatsapp/WhatsAppRealTime";
import WhatsAppAgentsHistorical from "../components/whatsapp/agents/WhatsAppAgentsHistorical";
import WhatsAppAgentsRealTime from "../components/whatsapp/agents/WhatsAppAgentsRealTime";
// Digitals Reports Components
import DigitalsContactsHistorical from "../components/digitals/contacts/DigitalsContactsHistorical";
// import DigitalsContactsRealTime from "../components/digitals/contacts/DigitalConstactsRealTime";
import DigitalsWhatsAppHistorical from "../components/digitals/whatsApp/DigitalsWhatsAppHistorical";
import DigitalsWhatsAppRealTime from "../components/digitals/whatsApp/DigitalsWhatsAppRealTime";
import DigitalsAgentsHistorical from "../components/digitals/agents/DigitalsAgentsHistorical";
import DigitalsAgentsRealNew from "../components/digitals/agents/DigitalsAgentsRealNew";
// Queue Reporta Components
import { QueueHistorical } from "../components/queue/QueueHistorical";
import { QueueRealTime } from "../components/queue/QueueRealTime";
import { QueueCuentas } from "../components/queue/QueueCuentas";
// Automatic Load Component
import AutomaticLoading from "../components/dial-batches/AutomaticLoading";
// Global Status Component
// import GlobalStatus from "../components/campaigns-status/GlobalStatus";
// Common Components
import WaitPage from "../components/common/WaitPage";
import Loader from "../components/common/Loader";

import {
  BarChartOutlined,
  // LineChartOutlined,
  AppstoreOutlined,
  // SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  ClockCircleOutlined,
  ContainerOutlined,
  ExclamationCircleFilled,
  WhatsAppOutlined,
  DeploymentUnitOutlined,
  //WechatOutlined,
} from "@ant-design/icons";
import Sequences from "../components/secuencies/Sequences";
import SequencesCA from "../components/secuencies/SequencesCA";
import { MasivoMensajes } from "../components/push-notifications/MasivoMensajes";
import { TipoAgentless } from "../components/push-notifications/TipoAgentless";
import { LayoutAsignacion } from "../components/reset-user/LayoutAsignacion";
import { ResetUsuarios } from "../components/reset-user/ResetUsuarios";

dayjs.extend(duration);

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem("Reportes", "sub1", <BarChartOutlined />, [
    getItem("Divisiones", "g1", <AppstoreOutlined />, [
      getItem("Tradicional", "g1-1", null, [
        getItem("Inbound", "g1-1-1", null, [
          getItem(
            "Detalle General",
            "g1",
            null,
            [getItem("Historicos", "33")],
            "group"
          ),
          getItem(
            "Detalle Interacciones",
            "g2",
            null,
            [getItem("Historicos", "35"), getItem("Tiempo Real", "36")],
            "group"
          ),
        ]),
        getItem("Outbound", "g1-1-2", null, [
          getItem(
            "Campañas",
            "g1",
            null,
            [getItem("Historicos", "1"), getItem("Tiempo Real", "2")],
            "group"
          ),
          getItem(
            "Agentes",
            "g2",
            null,
            [getItem("Historicos", "3"), getItem("Tiempo Real", "4")],
            "group"
          ),
        ]),
      ]),
      getItem("Hogar", "g1-2", null, [
        getItem("Inbound", "g1-2-1", null, [
          getItem(
            "Detalle General",
            "g1",
            null,
            [getItem("Historicos", "37")],
            "group"
          ),
          getItem(
            "Detalle Interacciones",
            "g2",
            null,
            [getItem("Historicos", "39"), getItem("Tiempo Real", "40")],
            "group"
          ),
        ]),
        getItem("Outbound", "g1-2-2", null, [
          getItem(
            "Campañas",
            "g1",
            null,
            [getItem("Historicos", "5"), getItem("Tiempo Real", "6")],
            "group"
          ),
          getItem(
            "Agentes",
            "g2",
            null,
            [getItem("Historicos", "7"), getItem("Tiempo Real", "8")],
            "group"
          ),
        ]),
      ]),
      getItem("Cuentas Clave", "g1-3", null, [
        getItem("Inbound", "g1-3-1", null, [
          getItem(
            "Detalle General",
            "g1",
            null,
            [getItem("Historicos", "41"), getItem("Tiempo Real", "42")],
            "group"
          ),
          getItem(
            "Detalle Interacciones",
            "g2",
            null,
            [getItem("Historicos", "43"), getItem("Tiempo Real", "44")],
            "group"
          ),
        ]),
        getItem("Outbound", "g1-3-2", null, [
          getItem(
            "Campañas",
            "g1",
            null,
            [getItem("Historicos", "9"), getItem("Tiempo Real", "10")],
            "group"
          ),
          getItem(
            "Tiempos de Conexión",
            "g2",
            null,
            [getItem("Historicos", "11"), getItem("Tiempo Real", "12")],
            "group"
          ),
        ]),
      ]),
      getItem("Manuales", "g1-4", null, [
        getItem(
          "Campañas",
          "g1",
          null,
          [getItem("Historicos", "13"), getItem("Tiempo Real", "14")],
          "group"
        ),
        getItem(
          "Agentes",
          "g2",
          null,
          [getItem("Historicos", "15"), getItem("Tiempo Real", "16")],
          "group"
        ),
      ]),
      getItem("WhatsApp", "g1-5", null, [
        getItem(
          "Campañas",
          "g1",
          null,
          [getItem("Historicos", "17"), getItem("Tiempo Real", "18")],
          "group"
        ),
        getItem(
          "Agentes",
          "g2",
          null,
          [getItem("Historicos", "19"), getItem("Tiempo Real", "20")],
          "group"
        ),
      ]),
      getItem("Proyectos Especiales", "g1-6", null, [
        getItem("Encuestas NPS", "g1-6-1", null, [
          getItem("Historicos", "45"),
          getItem("Tiempo Real", "46"),
        ]),
        getItem("Marcha Blanca", "g1-6-2", null, [
          getItem("Historicos", "47"),
          getItem("Tiempo Real", "48"),
        ]),
      ]),
    ]),
  ]),
  getItem("Digitales", "sub3", <WhatsAppOutlined />, [
    getItem("Contactos", "g1", null, [getItem("Historicos", "21")], "group"),
    getItem(
      "call_Detail_POC_WhatsApp",
      "g2",
      null,
      [getItem("Historicos", "22"), getItem("Tiempo Real", "23")],
      "group"
    ),
    getItem(
      "Agentes",
      "g3",
      null,
      [getItem("Historicos", "24"), getItem("Tiempo Real", "25")],
      "group"
    ),
  ]),
  getItem("Lotes de Marcación", "sub4", <AppstoreOutlined />, [
    getItem("Carga Automática de LM", "26"),
  ]),
  getItem("Planificaciones", "sub5", <DeploymentUnitOutlined />, [
    getItem("US EAST", "27"),
    getItem("CANADA", "28"),
  ]),
  getItem("Push Notifications", "sub6", <WhatsAppOutlined style={{color: "#26D366"}} />, [
    getItem("Envío Masivo Mensajes", "29"),
    getItem("Envío Tipo Agentless", "30"),
  ]),
  getItem("Reset de Usuarios", "sub7", <UserOutlined />, [
    getItem("Layout de Asignación", "31"),
    getItem("Reset de Usuarios", "32"),
  ]),
  {
    type: "divider",
  },
  // getItem("Estatus", "sub6", <SettingOutlined />, [
  //   getItem("Estatus Global de Campañas", "17"),
  // ]),
  getItem("Cerrar Sesión", "sub8", <LogoutOutlined />, [
    getItem("Salir", "29"),
  ]),
];
const { confirm } = Modal;

export default function Main({ Settings }) {
  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState("1-Historicos");
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignsSelected, setCampaignsSelected] = useState([]);
  const [campaignsHistorical, setCampaignsHistorical] = useState([]);
  const [agentsHistorical, setAgentsHistorical] = useState([]);
  const [keyHistorical, setKeyHistorical] = useState([]);
  const [keyAgentsHistorical, setKeyAgentsHistorical] = useState([]);
  const [digitalsHistorical, setDigitalsHistorical] = useState([]);
  const [digitalsAgentsHistorical, setDigitalsAgentsHistorical] = useState([]);
  const [queueHistorical, setQueueHistorical] = useState([]);
  const [autoLoadProgress, setAutoLoadProgress] = useState(0);
  // const [globalStateProgress, setGlobalStateProgress] = useState(0);
  // eslint-disable-next-line
  const [currentDate, setCurrentDate] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [disableDatepicker, setDisableDatepicker] = useState(false);
  const [datesChange, setDatesChange] = useState(false);
  // const [staticCampaing] = useState([
  //   {
  //     campaingName: "GEPP_Claveria3",
  //     campaingId: "1b5daa01-0fea-4104-a604-360280e523e4",
  //   },
  // ]);
  const [today, setToday] = useState([]);
  // Api Calls Authentication
  const auth = JSON.parse(Cookies.get("userData"));

  useEffect(() => {
    if (!Cookies.get("userData")) {
      navigate("/redfield/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let countdown = 30 * 60 * 1000;
    const timerId = setInterval(function () {
      countdown -= 1000;

      if (countdown <= 0) {
        Modal.info({
          title: "Información",
          content:
            "Su Sesión ha expirado. De click en 'Ok' para ser redirijido al Login.",
          centered: true,
          onOk: () => window.location.reload(),
        });
        clearInterval(timerId);
      }
    }, 1000);
    // eslint-disable-next-line
  }, []);

  const variantsBanner = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -100 },
  };
  const variantsSideMenu = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  };
  const variantsHistoricalRealTime = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  };

  const getCampaigns = (dates, division) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${startUnixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getCampaignsRealTime = (division) => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${unixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getKeyAccountsCampaigns = (dates) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getManualsCampaigns = (dates) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}?division=MANUALES`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getWhatsAppCampaigns = (dates, division) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${startUnixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getDigitalsCampaigns = (dates, byContacts = false, byPoc = false) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    let url;
    if (byContacts) {
      url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}${Settings.hanaContactsCampsQueryCtc}`;
    } else if (byPoc) {
      url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}${Settings.hanaContactsCampsQueryPoc}`;
    } else {
      url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}${Settings.hanaContactsCampsQuery}`;
    }
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getKeyAccountsCampaignsRealTime = () => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${unixDate}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getManualsCampaignsRealTime = () => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${unixDate}?division=MANUALES`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getWhatsAppCampaignsRealTime = (division) => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${unixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getDigitalsCampaignsRealTime = (byPoc = false) => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = !byPoc
      ? `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${unixDate}${Settings.hanaContactsCampsQuery}`
      : `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${unixDate}${Settings.hanaContactsCampsQueryPoc}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1686895200'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getCampaignsData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        campaingName: camp.name,
        campaingId: camp.id,
      };
    });
    setCampaignsHistorical(filterNameId);
  };
  const downloadCampHistoricalReport = (format) => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // const statcUrl =
    //   "https://gepp.redfield.kranon.cloud/toki/report/date/1685685600/format/Simple";
    const url = `${Settings.redfieldDomain}${Settings.tokiReport}${dateRange[0]}/to/${dateRange[1]}/format/${format}`;
    console.log(url);
    axios
      .post(`${url}`, campaignsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Campañas - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Campañas - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")} (${format}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getCampaignsRealTimeReport = (format) => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // const statcUrl =
    // "https://gepp.redfield.kranon.cloud/toki/report/format/Simple/realtime";
    const url = `${Settings.redfieldDomain}${Settings.tokiCampaignsReal}${format}/realtime`;
    axios
      .post(`${url}`, campaignsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Campañas - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            `Campañas - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )}(${format}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }

        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getAgentsData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        campaingName: camp.name,
        campaingId: camp.id,
      };
    });
    setAgentsHistorical(filterNameId);
  };
  const downloadAgentsHistoricalReport = () => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // const statcUrl =
    // "https://gepp.redfield.kranon.cloud/toki/report/agents/date/1685685600";
    const url = `${Settings.redfieldDomain}${Settings.tokiAgents}${dateRange[0]}/to/${dateRange[1]}`;
    axios
      .post(`${url}`, agentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Agentes - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Agentes - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getAgentsRealTimeReport = () => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    const url = `${Settings.redfieldDomain}${Settings.tokiAgentsReal}`;
    axios
      .post(`${url}`, agentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        // let fileName = res.headers["content-disposition"].split("filename=")[1];
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Agentes - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["content-disposition"].split("filename=")[1]
            `Agentes - Tiempo Real - ${selectedDate.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getKeyAccountsHistoricalData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        queueName: camp.name,
        queueId: camp.id,
      };
    });
    setKeyHistorical(filterNameId);
  };
  const getManualsHistoricalData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        queueName: camp.name,
        queueId: camp.id,
      };
    });
    setKeyHistorical(filterNameId);
  };
  const getKeyAccountsAgentsData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        queueName: camp.name,
        queueId: camp.id,
      };
    });
    setKeyAgentsHistorical(filterNameId);
  };
  const getManualsAgentsData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        queueName: camp.name,
        queueId: camp.id,
      };
    });
    setKeyAgentsHistorical(filterNameId);
  };
  const getWhatsAppCampaignsData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        campaingName: camp.name,
        campaingId: camp.id,
      };
    });
    setCampaignsHistorical(filterNameId);
  };
  const getWhatsAppAgentsData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        campaingName: camp.name,
        campaingId: camp.id,
      };
    });
    setAgentsHistorical(filterNameId);
  };
  const downloadKeyAccountsHistoricalReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // //   "https://gepp.redfield.kranon.cloud/toki/report/date/1685685600/format/Simple";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReport}/${dateRange[0]}/to/${dateRange[1]}/type/calls`;
    axios
      .post(`${url}`, keyHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Campañas - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `C Clave - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getKeyAccountsRealTimeReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // // "https://gepp.redfield.kranon.cloud/toki/report/format/Simple/realtime";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReal}/type/calls`;
    axios
      .post(`${url}`, keyHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Campañas - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            `C Clave - Tiempo Real - ${selectedDate.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }

        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const downloadKeyAgentsHistoricalReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // // "https://gepp.redfield.kranon.cloud/toki/report/agents/date/1685685600";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReport}/${dateRange[0]}/to/${dateRange[1]}/type/agents`;
    axios
      .post(`${url}`, keyAgentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Agentes - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `C Clave - Agentes - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getKeyAgentsRealTimeReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReal}/type/agents`;
    axios
      .post(`${url}`, keyAgentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        // let fileName = res.headers["content-disposition"].split("filename=")[1];
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Agentes - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["content-disposition"].split("filename=")[1]
            `C Clave - Agentes - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const downloadManualsHistoricalReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // //   "https://gepp.redfield.kranon.cloud/toki/report/date/1685685600/format/Simple";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReport}/${dateRange[0]}/to/${dateRange[1]}/type/calls`;
    axios
      .post(`${url}`, keyHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Campañas - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Manuales - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getManualsRealTimeReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // // "https://gepp.redfield.kranon.cloud/toki/report/format/Simple/realtime";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReal}/type/calls`;
    axios
      .post(`${url}`, keyHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Manuales - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            `Manuales - Tiempo Real - ${selectedDate.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }

        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const downloadManualAgentsHistoricalReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // // "https://gepp.redfield.kranon.cloud/toki/report/agents/date/1685685600";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReport}/${dateRange[0]}/to/${dateRange[1]}/type/agents`;
    axios
      .post(`${url}`, keyAgentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Manuales - Agentes - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Manuales - Agentes - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getManualAgentsRealTimeReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    const url = `${Settings.redfieldDomain}${Settings.tokiKeyAccountsReal}/type/agents`;
    axios
      .post(`${url}`, keyAgentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        // let fileName = res.headers["content-disposition"].split("filename=")[1];
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Manuales - Agentes - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["content-disposition"].split("filename=")[1]
            `Manuales - Agentes - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const downloadWhatsAppHistoricalReport = (format) => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // const statcUrl =
    //   "https://gepp.redfield.kranon.cloud/toki/report/date/1685685600/format/Simple";
    const url = `${Settings.redfieldDomain}${Settings.tokiReport}${dateRange[0]}/to/${dateRange[1]}/format/${format}`;
    console.log(url);
    axios
      .post(`${url}`, campaignsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `WhatsApp - Campañas - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `WhatsApp - Campañas - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")} (${format}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getWhatsAppRealTimeReport = (format) => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // const statcUrl =
    // "https://gepp.redfield.kranon.cloud/toki/report/format/Simple/realtime";
    const url = `${Settings.redfieldDomain}${Settings.tokiCampaignsReal}${format}/realtime`;
    axios
      .post(`${url}`, campaignsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `WhatsApp - Campañas - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            `WhatsApp - Campañas - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )}(${format}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }

        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const downloadWhatsAppAgentsHistoricalReport = () => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // const statcUrl =
    // "https://gepp.redfield.kranon.cloud/toki/report/agents/date/1685685600";
    const url = `${Settings.redfieldDomain}${Settings.tokiAgents}${dateRange[0]}/to/${dateRange[1]}`;
    axios
      .post(`${url}`, agentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `WhatsApp - Agentes - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `WhatsApp - Agentes - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getWhatsAppAgentsRealTimeReport = () => {
    setLoading(true);
    // Axios callback in next line
    // const mockUrl =
    //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    const url = `${Settings.redfieldDomain}${Settings.tokiAgentsReal}`;
    axios
      .post(`${url}`, agentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        // let fileName = res.headers["content-disposition"].split("filename=")[1];
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `WhatsApp - Agentes - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["content-disposition"].split("filename=")[1]
            `WhatsApp - Agentes - Tiempo Real - ${selectedDate.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getDigitalsHistoricalData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        queueName: camp.name,
        queueId: camp.id,
      };
    });
    setDigitalsHistorical(filterNameId);
  };
  const getDigitalsAgentsData = (campaigns) => {
    setCampaignsSelected(campaigns);
    const filterNameId = campaigns.map((camp) => {
      return {
        queueName: camp.name,
        queueId: camp.id,
      };
    });
    setDigitalsAgentsHistorical(filterNameId);
  };
  const downloadDigitalsHistoricalReport = (type) => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // //   "https://gepp.redfield.kranon.cloud/toki/report/date/1685685600/format/Simple";
    const url = `${Settings.redfieldDomain}${Settings.tokiDigitalsReport}/${dateRange[0]}/to/${dateRange[1]}/type/${type}`;
    axios
      .post(`${url}`, digitalsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Digitales - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            type === "contacts"
              ? `Contactos - Historicos - de ${selectedDateStart.format(
                "YYYY/MM/DD"
              )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
              : type === "calls"
                ? `call_Detail_POC - Historicos - de ${selectedDateStart.format(
                  "YYYY/MM/DD"
                )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
                : ``
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getDigitalsRealTimeReport = (type) => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // // "https://gepp.redfield.kranon.cloud/toki/report/format/Simple/realtime";
    const url = `${Settings.redfieldDomain}${Settings.tokiDigitalsReal}/type/${type}`;
    axios
      .post(`${url}`, digitalsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Digitales - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            type === "calls"
              ? `call_Detail_POC - Tiempo Real -${selectedDate.format(
                "YYYY/MM/DD"
              )}.zip`
              : ``
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const downloadDigitalsAgentsHistoricalReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    // // const statcUrl =
    // // "https://gepp.redfield.kranon.cloud/toki/report/agents/date/1685685600";
    const url = `${Settings.redfieldDomain}${Settings.tokiDigitalsReport}/${dateRange[0]}/to/${dateRange[1]}/type/agents`;
    axios
      .post(`${url}`, digitalsAgentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Digitales - Agentes - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Digitales - Agentes - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getDigitalsAgentsRealTimeReport = () => {
    setLoading(true);
    // // Axios callback in next line
    // // const mockUrl =
    // //   "https://run.mocky.io/v3/28c898fa-345d-46c3-ba1e-e645527e090f";
    const url = `${Settings.redfieldDomain}${Settings.tokiDigitalsReal}/type/agents`;
    axios
      .post(`${url}`, keyAgentsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        // let fileName = res.headers["content-disposition"].split("filename=")[1];
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Digitales - Agentes - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["content-disposition"].split("filename=")[1]
            `Digitales - Agentes - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  };
  const getAutomaticLoad = (mail, debugg) => {
    setLoading(true);
    setAutoLoadProgress(50);
    // Axios callback in next line
    // axios
    //   .get(`${mockUrl}`)
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/mera/contactlist/load'
    const url = `${Settings.redfieldDomain}${Settings.meraAuto}${
      debugg ? "yes" : "no"
    }`;
    axios({
      url: url,
      method: "POST",
      auth: {
        username: auth.username,
        password: auth.password,
      },
      data: {
        mail: [mail],
      },
      timeout: 90000,
    })
      .then((res) => {
        setLoading(false);
        mailSendSuccess("auto");
        setAutoLoadProgress(100);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 400) {
          noFilesModalWarning(`No se encontraron archivos en el path de SFTP`);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
        setAutoLoadProgress(0);
      });
  };
  // ------------------- Inbound -------------------
  const getQueuesData = (queue) => {
    const filterNameId = queue.map((camp) => {
      return {
        queueName: camp.name,
        queueId: camp.id,
      };
    });
    setQueueHistorical(filterNameId);
  };

  const getInboundTradicionalGeneral = (dates) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setCampaigns([{"name": "Detalle General", "id": "General"}]);
  }



  const getInboundTradicionalInteracciones = (dates, division) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/queues/date/1726984800?division=tradicional'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const getInboundTradicionalInteraccionesRealTime = (division) => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${unixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/queues/date/1726984800?division=tradicional'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }



  const getInboundHogarGeneral = (dates) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setCampaigns([{"name": "Detalle General", "id": "General"}]);
  }



  const getInboundHogarInteracciones = (dates, division) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/queues/date/1726984800?division=hogar'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const getInboundHogarInteraccionesRealTime = (division) => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${unixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/queues/date/1726984800?division=hogar'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }



  const getInboundCuentasGeneral = (dates) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setCampaigns([{"name": "Cuentas Clave", "id": "Cuentas"}]);
  }
  const getInboundCuentasGeneralRealTime = () => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setCampaigns([{"name": "Cuentas Clave", "id": "Cuentas"}]);
  }



  const getInboundCuentasInteracciones = (dates) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${startUnixDate}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/queues/date/1726984800'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const getInboundCuentasInteraccionesRealTime = () => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaKeyAccountsCamps}/${unixDate}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/queues/date/1726984800'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.queues.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }



  const getInboundEncuestas = (dates, division) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${startUnixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1726984800?division=nps'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const getInboundEncuestasRealTime = (division) => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${unixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1726984800?division=nps'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }



  const getInboundMarcha = (dates, division) => {
    // Date validation
    const d = dayjs.duration(dates[1].diff(dates[0]));
    const selectedDays = d.asDays() + 1;
    if (selectedDays > 7) {
      dateRangeExceeded();
      setSelectedDates([null, null]);
      return;
    }
    // Dates process
    let startDate = dayjs(dates[0])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    let endDate = dayjs(dates[1])
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const startUnixDate = startDate.unix();
    const endUnixDate = endDate.unix();
    setDateRange([startUnixDate, endUnixDate]);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${startUnixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1726984800?division=marcha_blanca'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const getInboundMarchaRealTime = (division) => {
    // Date process
    let selectedDate = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
    const unixDate = selectedDate.unix();
    setCurrentDate(unixDate);
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.hanaCampaigns}/${unixDate}?division=${division}`;
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/date/1726984800?division=marcha_blanca'
    // Axios callback in the next line
    axios
      .get(`${url}`, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((res) => {
        const orderCampaings = res.data.campaigns.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCampaigns(orderCampaings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
          setCampaigns([]);
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }



  const downloadInboundGeneralHistorico = (division) => {
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.tokiReportGeneral}/${dateRange[0]}/to/${dateRange[1]}`;
    const params = {
      division: division,
    }
    axios
      .post(`${url}`, params, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Queues - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Queues - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")} (${division}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const downloadInboundGeneralTiempoReal = (division) => {
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.tokiReportGeneral}/realtime`;
    const params = {
      division: division,
    }
    axios
      .post(`${url}`, params, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Queues - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            `Queues - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )} ${division}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }

        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const downloadInboundInteraccionesHistorico = (division) => {
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.tokiReportInteracciones}/${dateRange[0]}/to/${dateRange[1]}`;
    axios
      .post(`${url}`, queueHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Queues - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Queues - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")} (${division}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const downloadInboundInteraccionesTiempoReal = (division) => {
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.tokiReportInteracciones}/realtime`;
    axios
      .post(`${url}`, queueHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Queues - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            `Queues - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )} ${division}.zip`
          );
          document.body.appendChild(link);
          link.click();
        }

        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const downloadInboundEncuestasHistoricos = (format) => {
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.tokiReportAgentlessRange}/${dateRange[0]}/to/${dateRange[1]}?format=${format}`;
    axios
      .post(`${url}`, campaignsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDateStart = dayjs
          .unix(dateRange[0])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        const selectedDateEnd = dayjs
          .unix(dateRange[1])
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Campañas - Historicos - de ${selectedDateStart.format(
          "YYYY/MM/DD"
        )} a ${selectedDateEnd.format("YYYY/MM/DD")}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Campañas - Historicos - de ${selectedDateStart.format(
              "YYYY/MM/DD"
            )} a ${selectedDateEnd.format("YYYY/MM/DD")} (${format}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else if (err.response && err.response.status === 400) {
          noHistoricalPaths();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  const downloadInboundEncuestasTiempoReal = (format) => {
    setLoading(true);
    const url = `${Settings.redfieldDomain}${Settings.tokiReportAgentlessFormat}/${format}/realtime`;
    axios
      .post(`${url}`, campaignsHistorical, {
        auth: {
          username: auth.username,
          password: auth.password,
        },
        responseType: "blob",
      })
      .then((res) => {
        const selectedDate = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0);
        let fileName = `Campañas - Tiempo Real - ${selectedDate.format(
          "YYYY/MM/DD"
        )}`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([res.data], { type: res.headers["Content-Type"] }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["Content-Type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            // res.headers["Content-Disposition"].split("filename=")[1]
            `Campañas - Tiempo Real - ${selectedDate.format(
              "YYYY/MM/DD"
            )}(${format}).zip`
          );
          document.body.appendChild(link);
          link.click();
        }

        message.success("Informe descargado con éxito");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          noFilesRegistersFound();
        } else {
          errorApiModal(err.message);
        }
        setLoading(false);
      });
  }
  // const getGlobalCampaignsState = (mail) => {
  //   setLoading(true);
  //   setGlobalStateProgress(50);
  //   const url = `${Settings.redfieldDomain}${Settings.hanaStatus}`;
  //   // const staticUrl = 'https://gepp.redfield.kranon.cloud/hana/campaigns/status'
  //   // Axios callback in next line
  //   axios
  //     .post(
  //       url,
  //       { mail: [mail] },
  //       {
  //         auth: {
  //           username: Settings.auth.username,
  //           password: Settings.auth.password,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setLoading(false);
  //       mailSendSuccess("global");
  //       setGlobalStateProgress(100);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setGlobalStateProgress(0);
  //       if (err.response && err.response.status === 404) {
  //         noFilesRegistersFound();
  //       } else {
  //         errorApiModal(err.message);
  //       }
  //       setLoading(false);
  //     });
  // };
  const mailSendSuccess = (type) => {
    Modal.success({
      title: "Éxito",
      content:
        type === "auto"
          ? "Se ha enviado a su correo el Detalle de proceso. Puede tardar un momento en llegar."
          : type === "global"
            ? "Se ha enviado a su correo el Detalle de Campañas. Puede tardar un momento en llegar."
            : "",
      centered: true,
    });
  };
  const noFilesModalWarning = (message) => {
    Modal.warning({
      title: "Advertencia",
      content: message,
      centered: true,
    });
  };
  const noHistoricalPaths = () => {
    Modal.warning({
      title: "Atención",
      content: "No se encontraron paths Historicos",
      centered: true,
    });
  };
  const noFilesRegistersFound = () => {
    Modal.info({
      title: "Información",
      content: "No se encontraron Archivos/Registros",
      centered: true,
    });
  };
  const errorApiModal = (message) => {
    Modal.error({
      title: "Algo Salio mal. Contacta a soporte",
      content: message,
      centered: true,
    });
  };
  const dateRangeExceeded = () => {
    Modal.warning({
      title: "Atención",
      content: "Seleccione un rango de 7 días máximo",
      centered: true,
    });
  };
  const onClick = (e) => {
    if (e.key === "29" && e.domEvent.target.innerText === "Salir") {
      showConfirm();
    }

    if (e.domEvent.target.innerText === "Tiempo Real") {
      setCampaignsSelected([]);
      if (e.key === "2" || e.key === "4") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getCampaignsRealTime("tradicional");
      } else if (e.key === "6" || e.key === "8") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getCampaignsRealTime("hogar");
      } else if (e.key === "10" || e.key === "12") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getKeyAccountsCampaignsRealTime();
      } else if (e.key === "14" || e.key === "16") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getManualsCampaignsRealTime();
      } else if (e.key === "18" || e.key === "20") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getWhatsAppCampaignsRealTime("WHATSAPP");
      } else if (e.key === "23") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getDigitalsCampaignsRealTime(true);
      } else if (e.key === "25") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getDigitalsCampaignsRealTime(false);
      } else if (e.key === "36") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getInboundTradicionalInteraccionesRealTime("tradicional");
      } else if (e.key === "40") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getInboundHogarInteraccionesRealTime("hogar");
      } else if (e.key === "42") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getInboundCuentasGeneralRealTime();
      } else if (e.key === "44") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getInboundCuentasInteraccionesRealTime();
      } else if (e.key === "46") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getInboundEncuestasRealTime("nps");
      } else if (e.key === "48") {
        setToday(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
        setDisableDatepicker(true);
        getInboundMarchaRealTime("marcha_blanca");
      } else {
        setDisableDatepicker(false);
      }
    }

    if (e.domEvent.target.innerText === "Historicos") {
      setCampaigns([]);
      setCampaignsSelected([]);
      setDisableDatepicker(false);
    }

    //console.log(`${e.key}-${e.domEvent.target.innerText}`);
    setCurrentPage(`${e.key}-${e.domEvent.target.innerText}`);
  };
  const logOut = () => {
    Cookies.remove("userData");
    navigate("/redfield/login", { replace: true });
  };
  const showConfirm = () => {
    confirm({
      title: "Atención",
      icon: <ExclamationCircleFilled />,
      content: "¿Estas segur@ de que quieres cerrar sesión?",
      centered: true,
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk() {
        logOut();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="main-container">
      {Cookies.get("userData") && (
        <>
          <motion.div
            className="banner-container"
            variants={variantsBanner}
            initial="hidden"
            animate="visible"
            transition={{
              duration: 1,
            }}
          >
            <Card
              style={{
                cursor: "default",
                borderBottom: "1px solid #01479B",
                width: "100%",
              }}
              bodyStyle={{
                width: "100%",
                padding: "0.5rem 0.1rem",
                display: "flex",
                flexDirection: "row",
              }}
              hoverable
            >
              <Avatar
                size={64}
                icon={<UserOutlined />}
                style={{ marginLeft: "0.5rem" }}
              />
              <img
                src={`${Settings.staticMedia}GEPP_Logo_fondoblanco.jpeg`}
                alt="logo"
                style={{ width: "120px", marginLeft: "83%" }}
              />
            </Card>
          </motion.div>
          <Row>
            <div className="content">
              <motion.div
                className="side-menu-container"
                variants={variantsSideMenu}
                initial="hidden"
                animate="visible"
                transition={{
                  duration: 1,
                }}
              >
                <Card
                  style={{
                    height: "86.5vh",
                    width: "99%",
                    cursor: "default",
                    border: "1px solid #01479B",
                  }}
                  bodyStyle={{
                    width: "99%",
                    height: "90%",
                    padding: "0.5rem 0rem",
                    overflowY: "auto",
                  }}
                  hoverable
                >
                  <Menu
                    onClick={onClick}
                    style={{
                      width: "100%",
                      maxHeight: "90%",
                      overflowY: "auto",
                    }}
                    // defaultSelectedKeys={["5"]}
                    // defaultOpenKeys={["sub2"]}
                    mode="inline"
                    items={items}
                  />
                </Card>
              </motion.div>
              {currentPage === "1-Historicos" ||
              currentPage === "2-Tiempo Real" ||
              currentPage === "3-Historicos" ||
              currentPage === "4-Tiempo Real" ||
              currentPage === "5-Historicos" ||
              currentPage === "6-Tiempo Real" ||
              currentPage === "7-Historicos" ||
              currentPage === "8-Tiempo Real" ||
              currentPage === "9-Historicos" ||
              currentPage === "10-Tiempo Real" ||
              currentPage === "11-Historicos" ||
              currentPage === "12-Tiempo Real" ||
              currentPage === "13-Historicos" ||
              currentPage === "14-Tiempo Real" ||
              currentPage === "15-Historicos" ||
              currentPage === "16-Tiempo Real" ||
              currentPage === "17-Historicos" ||
              currentPage === "18-Tiempo Real" ||
              currentPage === "19-Historicos" ||
              currentPage === "20-Tiempo Real" ||
              currentPage === "21-Historicos" ||
              currentPage === "22-Historicos" ||
              currentPage === "23-Tiempo Real" ||
              currentPage === "24-Historicos" ||
              currentPage === "25-Tiempo Real" ||
              currentPage === "33-Historicos" ||
              currentPage === "34-Tiempo Real" ||
              currentPage === "35-Historicos" ||
              currentPage === "36-Tiempo Real" ||
              currentPage === "37-Historicos" ||
              currentPage === "38-Tiempo Real" ||
              currentPage === "39-Historicos" ||
              currentPage === "40-Tiempo Real" ||
              currentPage === "41-Historicos" ||
              currentPage === "42-Tiempo Real" ||
              currentPage === "43-Historicos" ||
              currentPage === "44-Tiempo Real"||
              currentPage === "45-Historicos" ||
              currentPage === "46-Tiempo Real"||
              currentPage === "47-Historicos" ||
              currentPage === "48-Tiempo Real" ? (
                <Campaigns
                  getCampaigns={getCampaigns}
                  getKeyAccountsCampaigns={getKeyAccountsCampaigns}
                  getManualsCampaigns={getManualsCampaigns}
                  getWhatsAppCampaigns={getWhatsAppCampaigns}
                  getDigitalsCampaigns={getDigitalsCampaigns}
                  campaigns={campaigns}
                  setCampaignsSelected={setCampaignsSelected}
                  getCampaignsData={getCampaignsData}
                  getAgentsData={getAgentsData}
                  getKeyAccountsHistoricalData={getKeyAccountsHistoricalData}
                  getManualsHistoricalData={getManualsHistoricalData}
                  getWhatsAppCampaignsData={getWhatsAppCampaignsData}
                  getWhatsAppAgentsData={getWhatsAppAgentsData}
                  getKeyAccountsAgentsData={getKeyAccountsAgentsData}
                  getManualsAgentsData={getManualsAgentsData}
                  getDigitalsHistoricalData={getDigitalsHistoricalData}
                  getDigitalsAgentsData={getDigitalsAgentsData}
                  disableDatepicker={disableDatepicker}
                  today={today}
                  currentPage={currentPage}
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                  campaignsSelected={campaignsSelected}
                  datesChange={datesChange}
                  setDatesChange={setDatesChange}

                  getInboundTradicionalGeneral={getInboundTradicionalGeneral}
                  getInboundTradicionalInteracciones={getInboundTradicionalInteracciones}
                  getInboundHogarGeneral={getInboundHogarGeneral}
                  getInboundHogarInteracciones={getInboundHogarInteracciones}
                  getInboundCuentasGeneral={getInboundCuentasGeneral}
                  getInboundCuentasInteracciones={getInboundCuentasInteracciones}
                  getInboundEncuestas={getInboundEncuestas}
                  getInboundMarcha={getInboundMarcha}
                  getQueuesData={getQueuesData}
                />
              ) : (
                <></>
              )}
              <motion.div
                className="historical-real-time-container"
                style={
                  currentPage === "26-Carga Automática de LM" ||
                  currentPage === "27-US EAST" ||
                  currentPage === "28-CANADA" ||
                  currentPage === "29-Envío Masivo Mensajes" ||
                  currentPage === "30-Envío Tipo Agentless" ||
                  currentPage === "31-Layout de Asignación" ||
                  currentPage === "32-Reset de Usuarios" ||
                  currentPage === "29-Salir"
                    ? { width: "92.1%" }
                    : {}
                }
                variants={variantsHistoricalRealTime}
                initial="hidden"
                animate="visible"
                transition={{
                  duration: 1,
                }}
              >
                <Card
                  style={{
                    height: "86.5vh",
                    width: "99%",
                    cursor: "default",
                    border: "1px solid #01479B",
                  }}
                  bodyStyle={{
                    width: "100%",
                    padding: "0.5rem",
                    overflow: "hidden",
                  }}
                  hoverable
                >
                  {(currentPage === "1-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "2-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "1-Historicos"
                          ? "1"
                          : currentPage === "2-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <CampaignsHistorical
                                  campaignsHistorical={campaignsHistorical}
                                  downloadCampHistoricalReport={
                                    downloadCampHistoricalReport
                                  }
                                  datesChange={datesChange}
                                />
                              ) : id === "2" ? (
                                <CampaignsRealTime
                                  campaignsHistorical={campaignsHistorical}
                                  getCampaignsRealTimeReport={
                                    getCampaignsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "3-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "4-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "3-Historicos"
                          ? "1"
                          : currentPage === "4-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          return {
                            label: (
                              <span
                                style={{
                                  cursor: "default",
                                  visibility: "hidden",
                                }}
                              >
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <AgentsHistorical
                                  agentsHistorical={agentsHistorical}
                                  downloadAgentsHistoricalReport={
                                    downloadAgentsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <AgentsRealTime
                                  agentsHistorical={agentsHistorical}
                                  getAgentsRealTimeReport={
                                    getAgentsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "5-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "6-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "5-Historicos"
                          ? "1"
                          : currentPage === "6-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <CampaignsHistorical
                                  campaignsHistorical={campaignsHistorical}
                                  downloadCampHistoricalReport={
                                    downloadCampHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <CampaignsRealTime
                                  campaignsHistorical={campaignsHistorical}
                                  getCampaignsRealTimeReport={
                                    getCampaignsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "7-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "8-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "7-Historicos"
                          ? "1"
                          : currentPage === "8-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          return {
                            label: (
                              <span
                                style={{
                                  cursor: "default",
                                  visibility: "hidden",
                                }}
                              >
                                <Icon />
                                {id === "7"
                                  ? "Historicos"
                                  : id === "8"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <AgentsHistorical
                                  agentsHistorical={agentsHistorical}
                                  downloadAgentsHistoricalReport={
                                    downloadAgentsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <AgentsRealTime
                                  agentsHistorical={agentsHistorical}
                                  getAgentsRealTimeReport={
                                    getAgentsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "9-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "10-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "9-Historicos"
                          ? "1"
                          : currentPage === "10-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <KeyHistoricalNew
                                  keyHistorical={keyHistorical}
                                  downloadKeyAccountsHistoricalReport={
                                    downloadKeyAccountsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <KeyRealTime
                                  keyHistorical={keyHistorical}
                                  getKeyAccountsRealTimeReport={
                                    getKeyAccountsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "11-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "12-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "11-Historicos"
                          ? "1"
                          : currentPage === "12-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <KeyAgentsHistorical
                                  keyAgentsHistorical={keyAgentsHistorical}
                                  downloadKeyAgentsHistoricalReport={
                                    downloadKeyAgentsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <KeyAgentsRealTime
                                  keyAgentsHistorical={keyAgentsHistorical}
                                  getKeyAgentsRealTimeReport={
                                    getKeyAgentsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "13-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "14-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "13-Historicos"
                          ? "1"
                          : currentPage === "14-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <ManualsCampaignsHistorical
                                  keyHistorical={keyHistorical}
                                  downloadManualsHistoricalReport={
                                    downloadManualsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <ManualsRealTime
                                  keyHistorical={keyHistorical}
                                  getManualsRealTimeReport={
                                    getManualsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "15-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "16-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "15-Historicos"
                          ? "1"
                          : currentPage === "16-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <ManualsAgentsHistorical
                                  keyAgentsHistorical={keyAgentsHistorical}
                                  downloadManualAgentsHistoricalReport={
                                    downloadManualAgentsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <ManualsAgentsRealTime
                                  keyAgentsHistorical={keyAgentsHistorical}
                                  getManualAgentsRealTimeReport={
                                    getManualAgentsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "17-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "18-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "17-Historicos"
                          ? "1"
                          : currentPage === "18-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <WhatsAppHistorical
                                  campaignsHistorical={campaignsHistorical}
                                  downloadWhatsAppHistoricalReport={
                                    downloadWhatsAppHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <WhatsAppRealTime
                                  campaignsHistorical={campaignsHistorical}
                                  getWhatsAppRealTimeReport={
                                    getWhatsAppRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "19-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "20-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "19-Historicos"
                          ? "1"
                          : currentPage === "20-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <WhatsAppAgentsHistorical
                                  agentsHistorical={agentsHistorical}
                                  downloadWhatsAppAgentsHistoricalReport={
                                    downloadWhatsAppAgentsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <WhatsAppAgentsRealTime
                                  agentsHistorical={agentsHistorical}
                                  getWhatsAppAgentsRealTimeReport={
                                    getWhatsAppAgentsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : currentPage === "21-Historicos" &&
                  campaignsSelected.length > 0 ? (
                    <Tabs
                      activeKey={
                        currentPage === "21-Historicos"
                          ? "1"
                          : currentPage === ""
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <DigitalsContactsHistorical
                                  digitalsHistorical={digitalsHistorical}
                                  downloadDigitalsHistoricalReport={
                                    downloadDigitalsHistoricalReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "22-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "23-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "22-Historicos"
                          ? "1"
                          : currentPage === "23-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <DigitalsWhatsAppHistorical
                                  digitalsHistorical={digitalsHistorical}
                                  downloadDigitalsHistoricalReport={
                                    downloadDigitalsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <DigitalsWhatsAppRealTime
                                  digitalsHistorical={digitalsHistorical}
                                  getDigitalsRealTimeReport={
                                    getDigitalsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "24-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "25-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "24-Historicos"
                          ? "1"
                          : currentPage === "25-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <DigitalsAgentsHistorical
                                  digitalsAgentsHistorical={
                                    digitalsAgentsHistorical
                                  }
                                  downloadDigitalsAgentsHistoricalReport={
                                    downloadDigitalsAgentsHistoricalReport
                                  }
                                />
                              ) : id === "2" ? (
                                <DigitalsAgentsRealNew
                                  digitalsAgentsHistorical={
                                    digitalsAgentsHistorical
                                  }
                                  getDigitalsAgentsRealTimeReport={
                                    getDigitalsAgentsRealTimeReport
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "33-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "34-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "33-Historicos"
                          ? "1"
                          : currentPage === "34-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <QueueHistorical
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundGeneralHistorico}
                                  division={"Tradicional"}
                                />
                              ) : id === "2" ? (
                                <QueueRealTime
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundGeneralTiempoReal}
                                  division={"Tradicional"}
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "35-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "36-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "35-Historicos"
                          ? "1"
                          : currentPage === "36-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <QueueHistorical
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundInteraccionesHistorico}
                                  division={"Tradicional"}
                                  />
                                ) : id === "2" ? (
                                  <QueueRealTime
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundInteraccionesTiempoReal}
                                  division={"Tradicional"}
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "37-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "38-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "37-Historicos"
                          ? "1"
                          : currentPage === "38-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <QueueHistorical
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundGeneralHistorico}
                                  division={"Hogar"}
                                />
                              ) : id === "2" ? (
                                <QueueRealTime
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundGeneralTiempoReal}
                                  division={"Hogar"}
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "39-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "40-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "39-Historicos"
                          ? "1"
                          : currentPage === "40-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <QueueHistorical
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundInteraccionesHistorico}
                                  division={"Hogar"}
                                  />
                                ) : id === "2" ? (
                                  <QueueRealTime
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundInteraccionesTiempoReal}
                                  division={"Hogar"}
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "41-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "42-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "41-Historicos"
                          ? "1"
                          : currentPage === "42-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <QueueCuentas
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundGeneralHistorico}
                                  division={"Cuentas Clave"}
                                />
                              ) : id === "2" ? (
                                <QueueCuentas
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundGeneralTiempoReal}
                                  division={"Cuentas Clave"}
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "43-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "44-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "43-Historicos"
                          ? "1"
                          : currentPage === "44-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          console.log(id);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <QueueHistorical
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundInteraccionesHistorico}
                                  division={"Cuentas Clave"}
                                  />
                                ) : id === "2" ? (
                                  <QueueRealTime
                                  queueHistorical={queueHistorical}
                                  download={downloadInboundInteraccionesTiempoReal}
                                  division={"Cuentas Clave"}
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "45-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "46-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "45-Historicos"
                          ? "1"
                          : currentPage === "46-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <CampaignsHistorical
                                  campaignsHistorical={campaignsHistorical}
                                  downloadCampHistoricalReport={
                                    downloadInboundEncuestasHistoricos
                                  }
                                />
                              ) : id === "2" ? (
                                <CampaignsRealTime
                                  campaignsHistorical={campaignsHistorical}
                                  getCampaignsRealTimeReport={
                                    downloadInboundEncuestasTiempoReal
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : (currentPage === "47-Historicos" &&
                    campaignsSelected.length > 0) ||
                  (currentPage === "48-Tiempo Real" &&
                    campaignsSelected.length > 0) ? (
                    <Tabs
                      activeKey={
                        currentPage === "47-Historicos"
                          ? "1"
                          : currentPage === "48-Tiempo Real"
                            ? "2"
                            : ""
                      }
                      items={[ContainerOutlined, ClockCircleOutlined].map(
                        (Icon, i) => {
                          const id = String(i + 1);
                          return {
                            label: (
                              <span style={{ cursor: "default" }}>
                                <Icon />
                                {id === "1"
                                  ? "Historicos"
                                  : id === "2"
                                    ? "Tiempo Real"
                                    : ""}
                              </span>
                            ),
                            key: id,
                            children:
                              id === "1" ? (
                                <CampaignsHistorical
                                  campaignsHistorical={campaignsHistorical}
                                  downloadCampHistoricalReport={
                                    downloadInboundEncuestasHistoricos
                                  }
                                />
                              ) : id === "2" ? (
                                <CampaignsRealTime
                                  campaignsHistorical={campaignsHistorical}
                                  getCampaignsRealTimeReport={
                                    downloadInboundEncuestasTiempoReal
                                  }
                                />
                              ) : null,
                          };
                        }
                      )}
                    />
                  ) : currentPage === "26-Carga Automática de LM" ? (
                    <AutomaticLoading
                      getAutomaticLoad={getAutomaticLoad}
                      autoLoadProgress={autoLoadProgress}
                    />
                  ) : currentPage === "27-US EAST" ? (
                    <Sequences Settings={Settings} />
                  ) : currentPage === "28-CANADA" ? (
                    <SequencesCA Settings={Settings} />
                  ) : currentPage === "29-Envío Masivo Mensajes" ? (
                    <MasivoMensajes Settings={Settings} auth={auth} />
                  ) : currentPage === "30-Envío Tipo Agentless" ? (
                    <TipoAgentless Settings={Settings} auth={auth} />
                  ) : currentPage === "31-Layout de Asignación" ? (
                    <LayoutAsignacion Settings={Settings} auth={auth} />
                  ) : currentPage === "32-Reset de Usuarios" ? (
                    <ResetUsuarios Settings={Settings} auth={auth} />
                  ) : (
                    <WaitPage />
                  )}
                </Card>
              </motion.div>
            </div>
          </Row>
        </>
      )}
      {loading ? <Loader /> : null}
    </div>
  );
}