import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import { UploadOutlined } from '@ant-design/icons';
import { Typography, Button, Upload, Select, Progress, message, Input } from 'antd';
import { ResumenCarga } from './ResumenCarga';
import '../../App.css';
import axios from 'axios';
const { Title, Text } = Typography;
const { TextArea } = Input;

export const MasivoMensajes = ({Settings, auth}) => {

    const [view, setView] = useState(false);
    const [submit, setSubmit] = useState(false);

    const variantsCHistorical = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    };

    // Archivo CSV
    const [fileList, setFileList] = useState([]);
    const [name, setName] = useState("");
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setName("");
        },
        beforeUpload: (file) => {
            setName(file.name);
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    // Api Templates
    const [templates, setTemplates] = useState([]);
    const [cargandoTemplates, setCargandoTemplates] = useState(false);
    const apiTemplates = async () => {
        setCargandoTemplates(true);
        try {
            const res = await axios.get(`${Settings.redfieldPushDomain}/notifications/templates`, {
                auth: {
                    username: auth.username,
                    password: auth.password,
                }
            });
            if(res.status===200) {
                setTemplates(res.data);
                setCargandoTemplates(false);
            }
        } 
        catch (error) {
            console.log(error);
            setCargandoTemplates(false);
        }
    }
    useEffect(() => {
        apiTemplates();
    }, [])

    // Api Numeros
    const [numeros, setNumeros] = useState([]);
    const apiNumeros = async () => {
        try {
            const res = await axios.get(`${Settings.redfieldPushDomain}/notifications/whatsappNumber`, {
                auth: {
                    username: auth.username,
                    password: auth.password,
                }
            });
            setNumeros(res.data);
        } 
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        apiNumeros();
    }, [])

    // Api Carga Archivos
    const [template, setTemplate] = useState("");
    const [numero, setNumero] = useState("");
    const [mensaje, setMensaje] = useState("");
    
    const onChangeTemplete = (value) => {
        setTemplate(value);

        const template = templates.find(template => template.id === value);
        setMensaje(template.message[0].content);
    }
    const onChangeNumero = (value) => {
        setNumero(value);
    }

    const [messageApi, contextHolder] = message.useMessage();
    const errorMen = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
        });
    };

    const uploadFile = async () => {
        if(fileList.length === 0) {
            errorMen("Sube un archivo CSV para continuar");
        }
        else if(template === "") {
            errorMen("Selecciona un template para continuar");
        }
        else if(numero === "") {
            errorMen("Selecciona un número para continuar");
        }
        else {
            try {
                const formData = new FormData();
                formData.append(name, fileList[0]);
                await axios.post(`${Settings.redfieldPushDomain}/notifications/uploadMessages/${numero}/${template}`, formData, {
                    auth: {
                        username: auth.username,
                        password: auth.password,
                    }
                });
                setSubmit(true);
                cargandoBarra();
            } 
            catch (error) {
                console.log(error);
                errorMen(error.response.data.error);
            }
        }
    }

    // Api Cargando
    const [progreso, setProgreso] = useState(0);
    const cargandoBarra = async () => {
        let num = 0;
        while(num<100) {
            try {
                const res = await axios.get(`${Settings.redfieldPushDomain}/notifications/upload/status/${name}`, {
                    auth: {
                        username: auth.username,
                        password: auth.password,
                    }
                });
                if(res.status===200) {
                    setProgreso((res.data).toFixed(0));
                    num = res.data
                    if(res.data===100) {
                        setTimeout(() => {
                            setView(true);
                        }, 4000);
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));
        }
    }

    const removeLineBreaks = (text) => {
        return text.replace(/(\r\n|\n|\r)/gm, '');
    };


    function Formulario() {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px"}}>
                <Text style={{fontSize: "16px", fontWeight: "500"}}>Defina los siguientes campos de envío</Text>

                <div style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                    <Text style={{fontSize: "16px", marginRight: "10px"}}>Archivo Fuente CSV:</Text>
                    <Upload {...props} accept=".csv" maxCount={1} className="upload-container">
                        <Button icon={<UploadOutlined />} disabled={submit || name!==""}>Subir CSV</Button>
                    </Upload>
                </div>

                <div style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                    <Text style={{fontSize: "16px", marginRight: "10px"}}>Template:</Text>
                    <Select
                        placeholder="Seleccionar Template"
                        value={template}
                        style={{ width: 200 }}
                        options={templates.map(template => ({ label: template.name, value: template.id }))}
                        onChange={onChangeTemplete}
                        disabled={submit || cargandoTemplates}
                        loading={cargandoTemplates}
                    />
                </div>

                {template!=="" &&
                    <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                        <TextArea style={{width: "450px", fontSize: "12px"}} placeholder="Autosize height based on content lines" autoSize={{ minRows: 1, maxRows: 3 }} value={removeLineBreaks(mensaje)} />
                    </div>
                }

                <div style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                    <Text style={{fontSize: "16px", marginRight: "10px"}}>Número de WhatsApp de Salida:</Text>
                    <Select
                        placeholder="Seleccionar Número"
                        value={numero}
                        style={{ width: 200 }}
                        options={numeros.map(numero => ({ label: numero.whatsappNumber, value: numero.whatsappNumber }))}
                        onChange={onChangeNumero}
                        disabled={submit}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    <Button type="primary" onClick={uploadFile} disabled={submit}>Ejecutar Carga Masiva</Button>
                </div>

                {submit &&
                    <>
                        <Progress type="circle" percent={progreso} style={{marginTop: "15px"}} />
                        <Text>Cargando...</Text>
                    </>
                }
            </div>
        )
    }

    return (
        <motion.div
            className="planifications-container"
            variants={variantsCHistorical}
            initial="hidden"
            animate="visible"
            transition={{
                duration: 1,
            }}
        >
            <div>
                <Title level={5}>Envío Masivo de Push Notifications por un Administrador</Title>

                {view ? <ResumenCarga name={name} Settings={Settings} auth={auth} /> : <Formulario />}

                {contextHolder}
            </div>
        </motion.div>
    )
}
